import debounce from 'lodash.debounce';
import domHelp from './lib/dom';
import ScrollMagic from 'scrollmagic';

class ScrollActivatedAnimation {
  constructor(el, onClass = 'active') {
    this.el = el;
    this.onClass = onClass;
    const controller = new ScrollMagic.Controller();
    const scene = new ScrollMagic.Scene({
      triggerElement: el,
      reverse: false
    }).addTo(controller)

    domHelp.addClass(this.el, 'ready');

    scene.on('start', (event) => {
      this.updateTarget(event.scrollDirection);
    });

    window.addEventListener('resize', debounce(() => {
      if (domHelp.getBrowserWidth() <= 960) {
        this.disable();
      } else {
        this.enable();
      }
    }, 400));

    this.el.addEventListener("animationend", debounce((event) => {
      if (domHelp.hasClass(event.target, 'animation-people')) {
        domHelp.addClass(this.el, 'completed');
      }
    }, 900, {
      maxWait: 10000
    }));
  }

  updateTarget(direction) {
    if (direction === 'FORWARD') {
      domHelp.addClass(this.el, this.onClass);
    }
  }

  disable() {
    domHelp.removeClass(this.el, 'ready');
    domHelp.removeClass(this.el, this.onClass);
  }

  enable() {
    domHelp.addClass(this.el, 'ready');
    domHelp.addClass(this.el, 'animated');
  }
}

export default ScrollActivatedAnimation;
