import domHelp from './lib/dom';
import ScrollActivatedAnimation from './scroll-activated-animation';
import CityAccordions from './city-accordions';

class CivicTheme {
  init() {
    domHelp.ready(() => {
      const cityAccordions = new CityAccordions();

      // Initialize scroll triggered elements
      const cityAnimation = document.querySelector('[data-scroll-target]');

      if (cityAnimation && domHelp.getBrowserWidth() > 960) {
        const scrollActivatedAnimation = new ScrollActivatedAnimation(
          cityAnimation,
          'animated'
        );
      }
    });
  }
}

export default CivicTheme;
