// Import Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

// Import Styles
import "./style/stylesheets/styles.scss";

// Import JS
import CivicTheme from "./js/civic-theme.js";

const theme = new CivicTheme();
theme.init();
