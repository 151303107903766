import domHelp from "./lib/dom.js";
import { expand, collapse } from "./lib/height-help.js";

class AccordionGroup {
  constructor(
    dataToggle = "[data-accordion-toggle]",
    dataReveal = "[data-accordion]",
    onClass = "open",
    dataGroup = "[data-accordion-group]",
    dataAux = "[data-aux]",
  ) {
    // Get attribute names for each selector
    const toggleAttr = dataToggle.match(/\[(.*)\]/)[1];
    const revealAttr = dataReveal.match(/\[(.*)\]/)[1];
    const auxAttr = dataAux.match(/\[(.*)\]/)[1];
    // group attribute which the nodes share
    const groupAttr = dataGroup.match(/\[(.*)\]/)[1];

    // Find all elements with toggle data attribute
    const toggles = document.querySelectorAll(dataToggle);

    for (const toggle of [...toggles]) {
      // Get all of the reveal elements that match each trigger and
      // create a class for it
      const toggleId = toggle.getAttribute(toggleAttr);
      const reveals = document.querySelectorAll(
        "[" + revealAttr + "=" + toggleId + "]"
      );
      const auxs = document.querySelectorAll(
        "[" + auxAttr + "=" + toggleId.slice(0, -2) + "]"
      );

      // Create a class with the height trigger, a node list of its reveals,
      // and the reveal and group attributes
      const accordionTrigger = new AccordionTrigger(
        toggle,
        reveals,
        auxs,
        onClass,
        revealAttr,
        groupAttr
      );
    }
  }
}

class AccordionTrigger {
  constructor(
    triggerNode,
    revealNodes,
    auxNodes,
    onClass,
    revealAttr,
    groupAttr) {
    this.trigger = triggerNode;
    this.reveals = revealNodes;
    this.auxs = auxNodes;
    this.onClass = onClass;
    // Group to which the nodes belong
    this.revealSelector = revealAttr;
    // Find all members of the group
    this.groupAccordions = document.querySelectorAll(
      "[" + groupAttr + "=" + this.trigger.getAttribute(groupAttr) + "]"
    );

    // Bind click handler
    this.bindTrigger();
  }

  bindTrigger() {
    this.trigger.addEventListener("click", event => {
      event.preventDefault();

      // Loop through group members
      this.updateGroups();

      // Loop through all of this trigger's reveals, expand/collapse them
      // and add the onClass
      this.updateReveals();

      // Loop through all of this trigger's auxillary elements
      this.updateAuxs();
    });
  }

  updateGroups() {
    for (const group of [...this.groupAccordions]) {
      if (group === this.trigger) {
        // Toggle onClass on group member if it's the reveals' trigger
        if (!domHelp.hasClass(this.trigger, this.onClass)) {
          domHelp.addClass(this.trigger, this.onClass);
        } else {
          domHelp.removeClass(this.trigger, this.onClass);
        }
        // If it's not the trigger remove onClass
      } else {
        domHelp.removeClass(group, this.onClass);
        // If it's also a 'reveal' node collapse it
        if (group.getAttribute(this.revealSelector)) {
          collapse(group);
        }
      }
    }
  }

  updateReveals() {
    for (const reveal of [...this.reveals]) {
      if (!domHelp.hasClass(this.trigger, this.onClass)) {
        // Already handled class removal above
        collapse(reveal);
      } else {
        domHelp.addClass(reveal, this.onClass);
        expand(reveal);
      }
    }
  }

  updateAuxs() {
    for (const aux of [...this.auxs]) {
      if (!domHelp.hasClass(this.trigger, this.onClass)) {
        // Already handled class removal above
        domHelp.removeClass(aux, this.onClass);
      } else {
        domHelp.addClass(aux, this.onClass);
      }
    }
  }

  collapseGroups() {
    for (const group of [...this.groupAccordions]) {
      if (group === this.trigger) {
        // Toggle onClass on group member if it's the reveals' trigger
        if (domHelp.hasClass(this.trigger, this.onClass)) {
          domHelp.removeClass(this.trigger, this.onClass);
        }

        if (group.getAttribute(this.revealSelector)) {
          domHelp.removeClass(this.group, this.onClass);
          collapse(group);
        }
      }
    }
  }

  collapseReveals() {
    for (const reveal of [...this.reveals]) {
      if (domHelp.hasClass(reveal, this.onClass)) {
        domHelp.removeClass(reveal, this.onClass);
        collapse(reveal);
      }
    }
  }

  collapseAll() {
    this.collapseGroups();
    this.collapseReveals();
  }
}

export default AccordionGroup;
